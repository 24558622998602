console.log('init')

const header = document.querySelector('header')
const menuBtn = document.querySelector('.btn-menu')

function toggleMenu(){
  header.classList.toggle('open')
}

function headerScrollable(){
  var scrolled = false
  function scroll(){
    const top = window.scrollY || window.pageYOffset
    if(top > 90){
      if(scrolled === false){
        header.classList.add('scrolled')
        scrolled = true
      }
    } else {
      if(scrolled === true){
        header.classList.remove('scrolled')
        scrolled = false
      }
    }
  }
  scroll()
  return scroll
}

menuBtn.addEventListener('click', toggleMenu, false)
window.addEventListener('scroll', headerScrollable(), false)